import React, { useState, useEffect } from 'react';
import moment from 'moment'; // Import moment
import LogoutButton from './LogoutButton';
import { useNavigate, useParams } from 'react-router-dom';
// import RunawayButton from './RunawayButton';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, BarElement, Legend);

const AdminWorkSchedule = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment());
  const [staff, setStaff] = useState([{ firstName: 'ch', lastName: 'be' }]);
  const [workSchedules, setWorkSchedules] = useState([]); // State to store the work schedules
  const [staffList, setStaffList] = useState([]);
  const [user, setUser] = useState('');
  const { cityId } = useParams();
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
          setUser(data.user)
          fetchWorkSchedule(currentDate); // Fetch work schedules for the initial date on page load
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  useEffect(() => {
    fetch('/api/admin/getStaff', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        role: 'nurse',
      }),
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        const filteredStaff = data
        // const filteredStaff = data.filter(staffMember => staffMember.PrimaryCityCalendarID === parseInt(cityId));
        setStaffList(filteredStaff);
      })
      .catch(error => console.error('Error fetching staff:', error));
  }, [cityId]); // Add cityId as a dependency

  useEffect(() => {
    updateChartData();
  }, [workSchedules]);

  const updateChartData = () => {
    const hours = Array.from({ length: 17 }, (_, i) => i + 7);
    const staffCounts = Array(17).fill(0);

    workSchedules.forEach((schedule) => {
      const startHour = moment(schedule.startTime).utcOffset(-5).hour();
      const endHour = moment(schedule.endTime).utcOffset(-5).hour();

      for (let i = startHour; i <= endHour; i++) {
        if (i >= 7 && i <= 23) {
          staffCounts[i - 7]++;
        }
      }
    });

    const overlayData = hours.map((hour) => {
      if (hour >= 7 && hour < 8) return 1;
      if (hour >= 8 && hour < 9) return 2;
      if (hour >= 9 && hour < 10) return 3;
      if (hour >= 10 && hour <= 19) return 4;
      if (hour >= 20 && hour <= 21) return 2;
      if (hour >= 22 && hour <= 23) return 1;
      return 0;
    });

    setChartData({
      labels: hours.map((hour) => moment({ hour }).format('hA')),
      datasets: [
        {
          label: 'Desired Staff Count',
          data: overlayData,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
        {
          label: 'Total Staff Scheduled',
          data: staffCounts,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    });
  };

  const formatDate = (inin) => {
    return moment(inin).format('MM-DD hh:mm A')
  }

  const fetchWorkSchedule = (newDate) => {
    fetch('/api/admin/getWorkSchedule', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        date: newDate.format('YYYY-MM-DD'),
        city: cityId,
      })
    })
      .then(response => response.json())
      .then(data => {
        const sortedData = data.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
        // console.log(sortedData)
        setWorkSchedules(sortedData);
      });
  };

  const deleteWorkSchedule = (uuid) => {
    // Example API endpoint: "/api/admin/checkAndDeleteWorkSchedule"
    // console.log(JSON.stringify({ uuid }))
    fetch('/api/admin/removeFromWorkSchedule', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uuid })
    })
      .then(response => response.json())
      .then(data => {
        fetchWorkSchedule(currentDate)
      })
      .catch(error => {
        console.error('Error deleting work schedule:', error);
      });
  };

  const handleAddToSchedule = () => {
    const staffId = document.getElementById('staff').value;
    const startTime = moment(currentDate).startOf('d').add(document.getElementById('startTime').value)
    const endTime = moment(currentDate).startOf('d').add(document.getElementById('endTime').value)
    const cityCalendarId = cityId;
    // console.log("startTime", moment(startTime).toISOString(), moment(currentDate).startOf('d').toISOString())
    // Check if the selected staff is not Aisha
    // const selectedStaffMember = staffList.find(member => member.id === staffId);
    // if (selectedStaffMember && selectedStaffMember.firstName === "Aisha") {
    //   alert("Aisha cannot be scheduled.");
    //   return;
    // }

    addToWorkSchedule(staffId, startTime, endTime, cityCalendarId);
  };

  const addToWorkSchedule = (staffId, startTime, endTime, cityCalendarId) => {
    // Example API endpoint: "/api/admin/checkAndDeleteWorkSchedule"
    // console.log(JSON.stringify({ uuid }))
    fetch('/api/admin/addToWorkSchedule', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ staffId, startTime, endTime, cityCalendarId })
    })
      .then(response => response.json())
      .then(data => {
        fetchWorkSchedule(currentDate)
      })
      .catch(error => {
        console.error('Error deleting work schedule:', error);
      });
  };

  const increaseDate = () => {
    const newDate = currentDate.clone().add(1, 'days');
    setCurrentDate(newDate);
    fetchWorkSchedule(newDate);
  };

  const decreaseDate = () => {
    const newDate = currentDate.clone().subtract(1, 'days');
    setCurrentDate(newDate);
    fetchWorkSchedule(newDate);
  };
  const requiredACL = 2

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <div className='adminParent adminTable'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button onClick={decreaseDate}>-</button>
        <div style={{ margin: '0 10px', minWidth: '230px' }}>{currentDate.format('dddd, MMMM DD, YYYY')}</div>
        <button onClick={increaseDate}>+</button>
      </div>
      <br/>

      {/* Table to display fetched work schedules */}
      <table>
        <thead>
          <tr>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Staff First Name</th>
            {
              (user.acl > requiredACL)
                ? <> <th>default City</th> <th>Delete</th> </>
                : null
            }
          </tr>
        </thead>
        <tbody>
          {workSchedules.map(eachWorkSchedule => (
            <tr key={eachWorkSchedule.uuid}>
              <td>{moment(eachWorkSchedule.startTime).format('MM-DD hh:mm A')}</td>
              <td>{formatDate(eachWorkSchedule.endTime)}</td>
              <td>{eachWorkSchedule.staff.firstName} {eachWorkSchedule.staff.lastName}</td> 
              {/* {console.log(eachWorkSchedule)} */}
              {/** show deleteWorkSchedule only if user.acl>3 */}
              {
                (user.acl > requiredACL)
                  ? <>
                    <td> {eachWorkSchedule.staff.PrimaryCityCalendarID}</td>
                    <td onClick={() => deleteWorkSchedule(eachWorkSchedule.id)}>🗑️</td> 
                    </>
                  : null
              }
            </tr>
          ))}
        </tbody>
      </table>
      { (user.acl > requiredACL) ? <p>For City ID: Houston = 1, Austin = 2, RGV = 4</p> : null}
      <br/>
      {(user.acl > requiredACL)
        ? <div className='adminTextField'>
          <h3>Add to schedule</h3>
          <label htmlFor="staff">Select staff: </label>
          <select id="staff">
          {staffList.map(staffMember => (
            <option key={staffMember.id} value={staffMember.id}>
              {staffMember.firstName} {staffMember.lastName} {staffMember.PrimaryCityCalendarID}
            </option>
          ))}
          </select>
          <br />
          <br />

          <label >Start time:</label>
          <input type="time" id="startTime" name="startTime" />

          <label >End time:</label>
          <input type="time" id="endTime" name="endTime" />
          <br />

          <button onClick={handleAddToSchedule}>Add to schedule</button>
        </div>
        : null
      }
      {(user.acl > requiredACL) && chartData && (
        <div className="chart-container">
          <Bar
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Nurse Staffing Levels',
                  font: {
                    size: 24
                  },
                },
                legend: {
                  display: true,
                  position: 'bottom',
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    stepSize: 1,
                  },
                  title: {
                    display: true,
                    text: 'Number of Nurses',
                    font: {
                      size: 20
                    },
                  },
                },
              },
            }}
          />
        </div>
      )}

      <div className="calendar-bottom-space">
        <button onClick={() => navigate('/admin/byCity/' + cityId)} className="navigate-button">
          Back
        </button>
      </div>

    </div>
  );
}

export default AdminWorkSchedule;
