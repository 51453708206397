import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './adminStyles.css'; // Make sure to import the CSS file

const CustomerServiceIssueBookings = ({ userDetails }) => {
  const { cityId } = useParams()
  const [bookings, setBookings] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch bookings with customerServiceIssuePending=true on component mount
    // fetch(`/api/admin/bookings?userId=${userDetails.userId}&customerServiceIssuePending=true`)
    //   .then(response => response.json())
    //   .then(data => {
    //     setBookings(data);
    //   })
    //   .catch(error => console.error('Error fetching bookings:', error));
    //   }, [userDetails.userId]);
  }, []);

  useEffect(() => {
    // Check user's authentication status when component mounts
    // This could be a call to your server or checking local storage/session storage for a valid token
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
        } else {
          // If not authenticated, redirect to login
          window.location.href = '/login';
        }
      });
  }, []);

  return (
    <div className="customer-service-issue-bookings">
      <h2>Customer Service Issue Bookings</h2>

      <table className="results-table">
        <thead>
          <tr>
            <th>Booking UUID</th>
            <th>Patient Name</th>
            <th>Date of Appointment</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {bookings.map(booking => (
            <tr key={booking.uuid}>
              <td>{booking.uuid}</td>
              <td>{booking.patientName}</td>
              <td>{booking.dateOfAppointment}</td>
              <td><a href={`/admin/specificBooking/${booking.uuid}`}>View Details</a></td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="calendar-bottom-space">
        <button onClick={() => navigate('/admin/byCity/' + cityId)} className="navigate-button">
          Back
        </button>
      </div>
    </div>
  );
};

export default CustomerServiceIssueBookings;
