import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { formatDate } from '../utility/module.js';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomerServiceContent = ({ bookingData, handleFieldChange }) => {
  // State for local changes
  const [localBookingData, setLocalBookingData] = useState(bookingData);
  const [nurses, setNurses] = useState([]);
  const [cities, setCities] = useState([]);
  const [noteReason, setNoteReason] = useState('late');
  const [noteText, setNoteText] = useState('');
  const [cancelNoteReason, setCancelNoteReason] = useState('');
  const [cancelNoteText, setCancelNoteText] = useState('');
  const [user, setUser] = useState('');
  const [notes, setNotes] = useState(bookingData.customerServiceCommunicationJSON || []);
  // const [adminNote, setAdminNote] = useState('');
  const [adminNotes, setAdminNotes] = useState(bookingData.adminNotes || []);
  const [adminNoteText, setAdminNoteText] = useState('');
  const [savedBookingData, setSavedBookingData] = useState(bookingData);
  const [blackList, setBlackList] = useState(bookingData.patient.blackList);
  const [isVIP, setIsVIP] = useState(bookingData.patient.isVIP);
  const [travelTime, setTravelTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleNoteSubmit = (e) => {
    e.preventDefault();

    const newNote = {
      reason: noteReason,
      text: noteText,
      date: new Date().toISOString(),
      author: user.email,
      provider: bookingData.primaryStaffId
    };
    setNotes([...notes, newNote]);
    // Clear input fields
    setNoteReason('');
    setNoteText('');
    // Append new note to existing JSON and update
    const updatedNotes = bookingData.customerServiceCommunicationJSON
      ? [...bookingData.customerServiceCommunicationJSON, newNote]
      : [newNote];

    // handleUpdate(updatedNotes); // Function to update the booking data
    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.uuid,
        customerServiceCommunicationJSON: updatedNotes
      })
    })
      .then(response => response.json())
      .then(data => {
      })
  };

  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setUser(data.user)
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  useEffect(() => {
    setSavedBookingData(bookingData);
  }, [bookingData]);

  const handleToggleChange = (field) => {
    // Only update if the current value is false
    if (!savedBookingData[field]) {
      setLocalBookingData({
        ...localBookingData,
        [field]: !localBookingData[field]
      });
      // Additional logic to handle API call or other actions when the field is toggled on
    }
  };
  const handleToggleChangeBoolean = (field) => {
    // Only update if the current value is false
    // if (!savedBookingData[field]) {
    setLocalBookingData({
      ...localBookingData,
      [field]: !localBookingData[field]
    });
    // Additional logic to handle API call or other actions when the field is toggled on
    // }
  };
  const handleBlackList = (field) => {
    // Only update if the current value is false
    if (!savedBookingData.patient.blackList) {
      setBlackList(!blackList);
    }
  };

  const handleVIP = (field) => {
    // Only update if the current value is false
    if (!savedBookingData.patient.isVIP) {
      setIsVIP(!isVIP);
    }
    // Additional logic to handle API call or other actions when the field is toggled on
  }

  const fetchNursesAndCities = async () => {
    const fetchedNurses = await getNurses();
    const fetchedCities = await getCities();
    setNurses(fetchedNurses);
    setCities(fetchedCities);
    // console.log(fetchedCities)
  };

  const handleAdminNoteSubmit = () => {
    const newAdminNote = {
      date: new Date().toISOString(),
      text: adminNoteText,
      author: user.email // Assuming the user object contains an email field
      // Add other fields as needed, such as reason, if applicable
    };
    const updatedAdminNotes = [...adminNotes, newAdminNote];

    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.uuid,
        adminNotes: updatedAdminNotes
      })
    })
      .then(response => response.json())
      .then(data => {
        setAdminNotes(updatedAdminNotes); // Update local state with new list of notes
        setAdminNoteText(''); // Clear admin note input field
      })
      .catch(error => {
        console.error('Error updating admin notes:', error);
      });
  };

  const renderAdminNoteSection = () => {
    if (user.acl > 2) {
      return (
        <div className='adminTextField'>
          <h3>Admin Notes</h3>
            Only viewable to admins. Use for notes that are not customer service issues.
            <br /> Note: <br/>
            {adminNotes.length > 0 && (
              <div className='adminTextField'>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Note</th>
                    <th>Author</th>
                  </tr>
                </thead>
                <tbody>
                  {adminNotes.map((note, index) => (
                    <tr key={index}>
                      <td>{formatDate(note.date)}</td>
                      <td>{note.text}</td>
                      <td>{note.author}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            )}
          <textarea
            value={adminNoteText}
            onChange={(e) => setAdminNoteText(e.target.value)}
            rows={4}
          />
          <br/>
          <button onClick={handleAdminNoteSubmit}>Save Admin Note</button>
        </div>
      );
    }
    return null;
  };

  // all changes should probably just be handled here, TBD
  const handleSave = (event) => {
    const button = event.target;
    // Prepare the data for the backend
    const changes = {};
    console.log('customer service issue', localBookingData.customerServiceIssuePending)
    updatePatientInfo()
    // Check each field for changes
    for (const key in localBookingData) {
      if (localBookingData[key] !== savedBookingData[key]) {
        changes[key] = localBookingData[key];
      }
    }

    // If there are changes, send them to the backend
    if (Object.keys(changes).length > 0) {
      const bookingUuid = localBookingData.uuid;
      const updatedData = { uuid: bookingUuid, ...changes };
      updatePatientInfo()
      // markCustomerServicePending()
      // if()
      fetch('/api/admin/updateBooking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData)
      })
        .then(response => response.json())
        .then(data => {
          // console.log('Booking updated with changes:', data);
          setSavedBookingData({
            ...savedBookingData,
            ...changes
          });
          button.classList.add('green');
          setTimeout(() => button.classList.remove('green'), 3000);
        })
        .catch(error => {
          console.error('Error updating booking with changes:', error);
        // Handle errors, e.g., by showing error messages
        });
    } else {
      console.log('No changes to save');
      // Handle the case where there are no changes
    }
  };

  useEffect(() => {
    setSavedBookingData(bookingData);
  }, [bookingData]);
  useEffect(() => {
    fetchNursesAndCities()
  }, [])

  // Replace these functions with the actual implementations that fetch data
  const getNurses = async () => {
    return fetch('/api/admin/getStaff', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        role: 'nurse',
      })
    })
      .then(response => response.json())
    // return []; // Replace with actual data fetching
  };

  const getCities = async () => {
    return fetch('/api/admin/getCityCalendars', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
    // return []; // Replace with actual data fetching
  };

  const handleChange = (event, field) => {
    if (field === 'startTime') {
      // console.log(event)
      // Update your state or handle the change as necessary
      // For example:
      setLocalBookingData({ ...localBookingData, startTime: event });
      handleFieldChange(field, event);
    } else {
      setLocalBookingData({ ...localBookingData, [field]: event.target.value });
      handleFieldChange(field, event.target.value);
    }
  };

  // Function to generate the minimum datetime-local input string
  const getMinDateTime = () => {
    const now = new Date();
    now.setMinutes(Math.ceil(now.getMinutes() / 30) * 30); // Round up to the next 30-minute interval
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now.toISOString().slice(0, 16); // Convert to datetime-local input format
  }

  // Function to mark booking as customerServicePending
  const markCustomerServicePending = () => {
    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.uuid,
        customerServiceIssuePending: (localBookingData.customerServiceIssuePending !== true),
      })
    })
      .then(response => response.json())
      .then(data => {
        // likely needs to update state such the list of services
      })
  };

  const updatePatientInfo = () => {
    fetch('/api/admin/updatePatient', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.patient.uuid,
        isVIP,
        blackList
      })
    })
      .then(response => response.json())
      .then(data => {
        // console.log('from medicalTabContent update booking response', data)
        // const sortedData = data.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
        // setWorkSchedules(sortedData);
        // likely needs to update state such the list of services
      })
  }

  // Function to cancel the booking
  const cancelBooking = (event) => {
    const button = event.target;

    const newNote = {
      reason: cancelNoteReason,
      text: cancelNoteText,
      date: new Date().toISOString(),
      author: user.email,
      provider: bookingData.primaryStaffId
    };
    setNotes([...notes, newNote]);
    // Clear input fields
    setCancelNoteReason('');
    setCancelNoteText('');
    // Append new note to existing JSON and update
    const updatedNotes = bookingData.customerServiceCommunicationJSON
      ? [...bookingData.customerServiceCommunicationJSON, newNote]
      : [newNote];

    // handleUpdate(updatedNotes); // Function to update the booking data
    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.uuid,
        customerServiceCommunicationJSON: updatedNotes
      })
    })
      .then(response => response.json())
      .then(data => {
      })
    fetch('/api/admin/cancelBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.uuid,
        reason: 'needs something here'
      })
    })
      .then(response => response.json())
      .then(data => {
        button.classList.add('green');
        setTimeout(() => button.classList.remove('green'), 3000);
        // likely needs to update state such the list of services
      })
  };

  // Function to change the city of the booking
  const moveBooking = (nurseId, cityId, newStartTime, event) => {
    const button = event.target;
    const bodyJSON = {
      uuid: localBookingData.uuid,
      nurseId: Number(nurseId),
      cityId,
      newStartTime,
    }
    // console.log("bodyJSON going into move booking", bodyJSON)
    fetch('/api/admin/moveBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyJSON)
    })
      .then(response => response)
      .then(data => {
        button.classList.add('green');
        setTimeout(() => button.classList.remove('green'), 3000);
      })
  };

  const changeCalendarBlockDuration = (travelTime, duration, event) => {
    const button = event.target;

    // Convert newStartTime to a Date object
    // const startTimeDateObject = new Date(newStartTime);
    const newStartTime = moment(localBookingData.startTime).subtract(travelTime, 'minute').toISOString();
    const newEndTime = moment(newStartTime).add(duration, 'minute').toISOString();

    fetch('/api/admin/updateCalendarBlock', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.calendarBlockUuid,
        startTime: newStartTime,
        endTime: newEndTime
      })
    })
      .then(response => response)
      .then(data => {
        button.classList.add('green');
        setTimeout(() => button.classList.remove('green'), 3000);
        // reload page
        window.location.reload();
      })
  }
  return (
    <div className='adminParent adminTabSection'>
      {/* <button onClick={() => cancelBooking()}>Cancel Booking</button><br/> */}
      <div className='adminTextField'>
        <h3>Cancel booking</h3>
        <form>
          <label>
            Reason: {'  '}
            <select value={cancelNoteReason} onChange={(e) => { setCancelNoteText(e.target.id); setCancelNoteReason(e.target.value) }}>
            <option value="">Please select a reason</option>
              <option value="Wrong Patient" id="Wrong Patient (Recreated)">Wrong Patient (Recreated)</option>
              <option value="No Show" id="Patient Not Here">Patient Not Here</option>
              <option value="Patient Canceled - other" id="Patient Canceled - other">Patient Canceled - other reason</option>
              <option value="Patient Canceled - late" id="Patient Canceled - late">Patient Canceled because we were running late</option>
              <option value="Patient canceled due to travel fee" id="Patient canceled due to travel fee">Patient canceled due to travel fee</option>
              <option value="Bad Patient" id="Rambunctious Patient">We canceled because we do not like the patient</option>
              <option value="Outside Service Area" id="Outside Service Area">Outside service area</option>
              <option value="Patient not answering dooor/no show" id="Patient not answering dooor/no show">Patient not answering dooor/no show</option>
              <option value="Patient Health History" id="Patient Health History">Patient health history doesn't allow drip</option>
              <option value="test booking" id="test booking">Test booking</option>
            </select>
          </label>
          {/* <label>
            Note: <br/>
            <textarea value={cancelNoteText} onChange={(e) => setCancelNoteText(e.target.value)} />
          </label> */}
          <br />
          <button type="submit" onClick={(e) => cancelBooking(e)} disabled={!cancelNoteReason}>Cancel Booking</button>
        </form>
      </div>
      <div>
      <div className="toggle-button">
      <div className='adminTextField'>
        <h3>Change booking status</h3>
          <label>
            Customer Service Pending:
            {console.log(localBookingData.customerServiceIssuePending)}
            <input
              type="checkbox"
              checked={localBookingData.customerServiceIssuePending}
              onClick={() => { console.log('here'); handleToggleChangeBoolean('customerServiceIssuePending') } }
            />
            <span className="slider"></span>
          </label>
          <div className="toggle-button">
          <label>
            Blacklist Customer:
            <input
              type="checkbox"
              checked={localBookingData.patient.blackList}
              onChange={() => handleBlackList('patient.blackList')}
            />
            <span className="slider"></span>
          </label>
        </div>

        <div className="toggle-button">
          <label>
            VIP Customer:
            <input
              type="checkbox"
              checked={localBookingData.patient.isVIP}
              onChange={() => handleVIP('patient.isVIP')}
            />
            <span className="slider"></span>
          </label> <br/>
          <button onClick={handleSave}>Save Status Change</button>
        </div>
      </div>
      </div>
        {localBookingData.blackList && <div className="alert alert-danger">Customer is Blacklisted</div>}
        {localBookingData.isVIP && <div className="alert alert-success">VIP Customer</div>}
        <div className='adminTextField'>
          <h3>Move Appointment</h3>
            Time:
            <ReactDatePicker
              selected={localBookingData.startTime ? new Date(localBookingData.startTime) : null}
              onChange={(date) => handleChange(date, 'startTime')}
              showTimeSelect
              timeIntervals={15} // 15 minute intervals
              timeFormat="hh:mm a"
              dateFormat="yyyy-MM-dd hh:mm a"
              minDate={new Date()} // Set the minimum date to the current date
              placeholderText="Change Time"
            /> <br/>
            {/* <input type="datetime-local"
                  placeholder="Change Time"
                  onChange={(e) => handleChange(e, 'startTime')}
                  min={getMinDateTime()} // Set the minimum date and time to the current date and time
                  value={moment(localBookingData.startTime).format('YYYY-MM-DDTHH:mm')}
                  step={'900'}
            /> <br/> */}
            Nurse: <select onChange={(e) => { handleChange(e, 'staff.id') }}>
              <option value= {localBookingData.staff.id}> {localBookingData.staff.firstName}</option>
              {nurses.map((nurse) => (
                <option key={nurse.id} value={nurse.id}>
                  {nurse.firstName} {nurse.lastName}
                </option>
              ))}
            </select>
            {'  '}
            City:
            <select value={localBookingData.cityCalendarId} onChange={(e) => {
              const userConfirmed = window.confirm("Are you sure you want to change the city?");
              if (userConfirmed) {
                handleChange(e, 'cityCalendarId');
              } else {
                // Optionally, reset the select value to the previous value if the user cancels the change
                e.target.value = localBookingData.cityCalendarId;
              }
            }}>
              <option value="">Select a city</option>
              {cities.map((city, index) => (
                <option key={index} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
            <button onClick={(event) => { moveBooking(localBookingData.staff.id, localBookingData.cityCalendarId, localBookingData.startTime, event) }}>
              Move Booking
              </button>
          </div>
          <div className='adminTextField'>
          <h3>Change Calendar Block Duration</h3>
            Add time before appointment (minutes): {'  '}
            <select onChange={(e) => { setTravelTime(e.target.value) }} value={travelTime}>
              {[...Array(10)].map((_, index) => (
                <option key={index} value={(index) * 15}>
                  {(index) * 15} minutes
                </option>
              ))}
            </select> <br/>
            Duration (minutes): {' '}
            <select onChange={(e) => { setDuration(e.target.value) }} value={duration}>
              {[...Array(12)].map((_, index) => (
                <option key={index} value={(index + 1) * 15}>
                  {(index + 1) * 15} minutes
                </option>
              ))}
            </select>
            <button onClick={(event) => { changeCalendarBlockDuration(travelTime, duration, event) }}>
              Submit Time Change
            </button>
          </div>
          {renderAdminNoteSection()}
        <div>
      <h3>Customer Service Notes</h3>
      <hr />
      <p>Existing notes</p>
        {notes.length > 0 && (
          <div className='adminTextField'>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Reason</th>
                <th>Note</th>
                <th>Author</th>
              </tr>
            </thead>
            <tbody>
              {notes.map((note, index) => (
                <tr key={index}>
                  <td>{formatDate(note.date)}</td>
                  <td>{note.reason}</td>
                  <td>{note.text}</td>
                  <td>{note.author}</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        )}
      <p>Add New Note</p>
      <div className='adminTextField'>
        <form onSubmit={handleNoteSubmit}>
          <label>
            Reason: {'  '}
            <select value={noteReason} onChange={(e) => setNoteReason(e.target.value)}>
              <option value="late">Late</option>
              <option value="nurse Issue">Nurse Issue other than stick</option>
              <option value="missed Stick">Missed stick</option>
              <option value="scheduling Impossible">Scheduling impossible</option>
              <option value="difficult Patient">Difficult Patient</option>
              <option value="Technical problem">Technical problem</option>
            </select>
          </label>
          <br />
          <br />
          <label>
            Note: <br/>
            <textarea value={noteText} onChange={(e) => setNoteText(e.target.value)} />
          </label>
          <br />
          <button type="submit" onClick={ () => handleNoteSubmit}>Submit Note</button>
        </form>
      </div>

    </div>
      </div>
      {/* <h3>Other To do</h3> */}
      <hr/>
      <p className='todo'>
        {/* * Nurse selection is going to be too complicate for a drop down. It will probably need a pop up once we're past Houston <br/> */}

      </p>

    </div>
  );
}

export default CustomerServiceContent;
