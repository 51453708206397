import React from 'react';

const ServiceSelectionModal = ({ services, fetchServices, selectedServices, setSelectedServices, onConfirm, onClose }) => {
  // Function to handle checkbox changes
  const handleCheckboxChange = (serviceId) => {
    console.log(serviceId, 'service id')
    setSelectedServices(prevSelectedServices => {
      if (prevSelectedServices.includes(serviceId)) {
        // If the service is already selected, remove it from the selection
        return prevSelectedServices.filter(uuid => uuid !== serviceId);
      } else {
        // Otherwise, add the service to the selection
        return [...prevSelectedServices, serviceId];
      }
    });
  };

  // Function to handle confirm button click
  const handleConfirm = () => {
    console.log('handle confirm selectedServices', selectedServices)
    console.log('handle confirm services', services)
    const selectedServiceObjects = selectedServices.map(uuid => services.find(service => service.uuid === uuid));
    console.log('confirm selectedServiceObjects', selectedServiceObjects)
    onConfirm(selectedServiceObjects);
    setSelectedServices([]); // Reset selected services after confirming
    // fetchServices(); // reset services object
  };

  return (
    <div className="modal">
      <h2>Select Services</h2>
      {services.map(service => (
        <div key={service.uuid} className="service-option">
          <label>
            <input
              type="checkbox"
              checked={selectedServices.includes(service.uuid)}
              onChange={() => handleCheckboxChange(service.uuid)}
            />
            {service.name} - ${service.price}
          </label>
        </div>
      ))}
      <div className="modal-actions">
        <button onClick={handleConfirm}>Add Selected Services</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default ServiceSelectionModal;
