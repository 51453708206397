import React, { useState, useEffect } from 'react';
import ServicesTable from './ServicesTable.js';
import { PaymentForm, CreditCard, GooglePay, ApplePay } from 'react-square-web-payments-sdk';
import ServiceSelectionModal from './ServiceSelectionModal'; // This is a new component for the modal
import BraintreeDropIn from '../payments/BraintreeDropIn.js';
import './PaymentTabContent.css';
// import booking from '../../../../../models/booking.js';
// import booking from '../../../../../models/booking.js';

const PaymentTabContent = ({ bookingData, handleFieldChange, setChangeMade }) => {
  const [paymentStatus, setPaymentStatus] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [services, setServices] = useState([]);
  const [addons, setAddons] = useState([]);
  const [user, setUser] = useState('');
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [showServicesModal, setShowServicesModal] = useState(false);
  const [showAddonModal, setShowAddonModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tipWasSelected, setTipWasSelected] = useState(false)
  const [helcimCheckoutToken, setHelcimCheckoutToken] = useState('');
  const [isHelcimScriptLoaded, setIsHelcimScriptLoaded] = useState(false);
  const [isBraintreeScriptLoaded, setIsBraintreeScriptLoaded] = useState(false);
  const [unsavedDiscount, setUnsavedDiscount] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showOthers, setShowOthers] = useState(true);

  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setUser(data.user)
          // fetchWorkSchedule(currentDate); // Fetch work schedules for the initial date on page load
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  // load helcim script
  useEffect(() => {
    const helcimScript = document.createElement('script');
    const braintreeScript1 = document.createElement('script');
    const braintreeScript2 = document.createElement('script');
    const braintreeScript3 = document.createElement('script');
    const braintreeScript4 = document.createElement('script');
    helcimScript.src = 'https://secure.helcim.app/helcim-pay/services/start.js';
    braintreeScript1.src = 'https://js.braintreegateway.com/web/dropin/1.42.0/js/dropin.min.js';
    braintreeScript2.src = 'http://code.jquery.com/jquery-3.2.1.min.js';
    braintreeScript3.src = 'https://js.braintreegateway.com/web/3.99.0/js/client.min.js';
    braintreeScript4.src = 'https://js.braintreegateway.com/web/3.99.0/js/apple-pay.min.js';
    helcimScript.type = 'text/javascript';
    braintreeScript1.type = 'text/javascript';
    braintreeScript2.type = 'text/javascript';
    braintreeScript3.type = 'text/javascript';
    braintreeScript4.type = 'text/javascript';
    braintreeScript2.crossOrigin = 'anonymous';
    helcimScript.onload = () => setIsHelcimScriptLoaded(true); // Set flag when script is loaded
    braintreeScript2.onload = () => setIsBraintreeScriptLoaded(true); // Set flag when script is loaded
    document.body.appendChild(helcimScript);
    document.body.appendChild(braintreeScript1);
    document.body.appendChild(braintreeScript2);
    document.body.appendChild(braintreeScript3);
    document.body.appendChild(braintreeScript4);

    return () => {
      document.querySelectorAll('script[src="https://secure.helcim.app/helcim-pay/services/start.js"]').forEach(el => el.remove());
      document.querySelectorAll('script[src="https://js.braintreegateway.com/web/dropin/1.42.0/js/dropin.min.js"]').forEach(el => el.remove());
      document.querySelectorAll('script[src="https://js.braintreegateway.com/web/3.99.0/js/client.min.js"]').forEach(el => el.remove());
      document.querySelectorAll('script[src="https://js.braintreegateway.com/web/3.99.0/js/apple-pay.min.js"]').forEach(el => el.remove());
      document.querySelectorAll('script[src="http://code.jquery.com/jquery-3.2.1.min.js"]').forEach(el => el.remove());
    };
  }, []);

  useEffect(() => {
    fetchServices();
    fetchAddons();
  }, []);

  // Updated to calculate total based on bookingData.servicePerformeds
  useEffect(() => {
    const newTotal = bookingData.servicePerformeds.reduce((sum, service) => {
      return sum + parseFloat(service.amount);
    }, 0);
    setTotalAmount(newTotal);
  }, [bookingData.servicePerformeds, selectedServices, selectedAddons]);

  useEffect(() => {
    setChangeMade(false)
  }, [bookingData.servicePerformeds, bookingData, selectedServices, selectedAddons]);

  useEffect(() => {
    const newTotalPaid = bookingData.patientPayments.reduce((sum, service) => {
      return sum + parseFloat(service.amount);
    }, 0)
    const newDueAmount = newTotalPaid - totalAmount
    setPaidAmount(newTotalPaid)
    setDueAmount(newDueAmount)
  }, [bookingData.patientPayments, bookingData.servicePerformeds, selectedServices, selectedAddons]);

  const hideOtherPayments = () => {
    setShowOthers(false)
  }

  const showOtherPayments = () => {
    setShowOthers(true)
  }

  const fetchServices = async () => {
    try {
      const response = await fetch('/api/admin/getServices', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          staffId: user.id,
          acl: user.acl,
        })
      });
      let data = await response.json();
      // console.log(data)
      data = data.filter(ea => !ea.AddOn)
      data.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })

      setServices(data);
      // setAllServices(data);
      calculateTotal(data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  const fetchAddons = async () => {
    try {
      const response = await fetch('/api/admin/getServices', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          staffId: user.id,
          acl: user.acl,
        })
      });
      let data = await response.json();
      data = data.filter(ea => ea.AddOn)
      data.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })
      setAddons(data);
      calculateTotal(data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  // initialize new helcim checkout whenever total amount due changes or tip is selected (for no tip cases)
  useEffect(() => {
    if (tipWasSelected && dueAmount >0) {
      // Set a timeout to delay the fetch call
      setIsDisabled(true); // Disable the button for the token to be available
      const timeoutId = setTimeout(() => {
        fetch('/api/intializeHelcimCheckout/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: dueAmount,
            cityID: bookingData.cityCalendarId
          })
        })
          .then(response => response.json())
          .then(data => {
            setHelcimCheckoutToken(data[0].checkoutToken);
            setIsDisabled(false); // Re-enable the button
          // setHelcimSecretToken(data[0].secretToken);
          })
          .catch(error => {
            console.error('Error fetching booking details:', error);
          });
      }, 1500); // 1500 milliseconds = 1.5 seconds

      // Clear the timeout if the component unmounts or if the dependencies change
      return () => clearTimeout(timeoutId);
    }
  }, [tipWasSelected, dueAmount]);

  // helcim show iframe
  const handlePaymentClick = async () => {
    if (isHelcimScriptLoaded && window.appendHelcimPayIframe) {
      // Replace `helcimCheckoutToken` with actual token value
      window.appendHelcimPayIframe(helcimCheckoutToken, true);
    } else {
      console.error('Payment script not loaded yet.');
    }
  };

  const processBraintreeTransaction = async (nonce, setIsBraintreeDisabled) => {
    try {
      setIsBraintreeDisabled(true)
      setIsDisabled(true)
      const response = await fetch('/api/processPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          processor: 'braintree',
          nonce,
          totalAmount: dueAmount,
          bookingUuid: bookingData.uuid,
          tip: bookingData.tip,
          note: bookingData.servicePerformeds.reduce((total, easp) => {
            return (total + easp.service.name + ' ')
          }, ''),
          bookingData,
        })
      });
      const paymentResponse = await response.json();
      if (paymentResponse.newRecord) {
        setPaymentStatus('Payment successful');
        const updatedServices = [...bookingData.patientPayments, paymentResponse.newRecord];
        // squareButton.classList.add('green');
        // setTimeout(() => squareButton.classList.remove('green'), 3000);
        handleFieldChange('patientPayments', updatedServices);
        handleFieldChange('patientPaid', true);
        // window.location.reload();
      } else {
        setPaymentStatus('Payment failed');
        setIsBraintreeDisabled(false)
        setIsDisabled(false)
        // squareButton.classList.add('red');
        // const style = document.createElement('style');
        // style.type = 'text/css';
        //         style.innerHTML = `
        //   .c-jWYnUm {
        //     background-color: red !important;
        //     color: white !important;
        //   }
        // `;
        //         document.head.appendChild(style);
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setPaymentStatus('Error processing payment');
      //       squareButton.style.backgroundColor = 'red';
      //       const style = document.createElement('style');
      //       style.type = 'text/css';
      //       style.innerHTML = `
      // .c-jWYnUm {
      //   background-color: red !important;
      //   color: white !important;
      // }
      // `;
      // document.head.appendChild(style);
    }
  }

  // creates payment request dependency and handles completed payment
  useEffect(() => {
    const handlePaymentMessage = async (event) => {
      const helcimPayJsIdentifierKey = 'helcim-pay-js-' + helcimCheckoutToken;

      if (event.data.eventName === helcimPayJsIdentifierKey) {
        if (event.data.eventStatus === 'ABORTED') {
          console.error('Transaction failed!', event.data.eventMessage);
        }

        if (event.data.eventStatus === 'SUCCESS') {
          const helcimResponse = JSON.parse(event.data.eventMessage);
          const paymentResponse = await fetch('/api/processPayment', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              nonce: helcimResponse.data.data.transactionId,
              totalAmount: Number(dueAmount),
              bookingUuid: bookingData.uuid,
              tip: bookingData.tip,
              note: 'react payment',
              // note: bookingData.servicePerformeds.reduce((total, easp) => {
              //   return (total + easp.service.name + ' ')
              // }, ''),
              bookingData,
              extraStuff: helcimResponse.data.data
            })
          })
          if (paymentResponse.status === 200) {
            // console.log(paymentResponse)
            setPaymentStatus('Payment successful, please reload page if payment does not appear');
            // const updatedServices = [...bookingData.patientPayments, paymentResponse.newRecord];
            // squareButton.classList.add('green');
            // setTimeout(() => squareButton.classList.remove('green'), 3000);
            // handleFieldChange('patientPayments', updatedServices);
            // handleFieldChange('patientPaid', true);
            fetch('/api/admin/updateBooking', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                uuid: bookingData.uuid,
                discount: bookingData.discount || 0,
                tip: bookingData.tip,
                patientPaid: true,
              })
            })
              .then(response => response.json())
              .then(data => {
                setIsDisabled(true)
                // const updatedServices = [...bookingData.patientPayments, paymentResponse.newRecord];
                // handleFieldChange('patientPayments', updatedServices);
                handleFieldChange('patientPaid', true);
                // window.location.reload();
              });
          } else {
            setPaymentStatus('Payment failed');
            // squareButton.classList.add('red');
            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `
      .c-jWYnUm {
        background-color: red !important;
        color: white !important;
      }
    `;
            document.head.appendChild(style);
          }
        }
      }
    };
    // Add event listener
    window.addEventListener('message', handlePaymentMessage);

    // Cleanup function
    return () => {
      window.removeEventListener('message', handlePaymentMessage);
    };
  }, [helcimCheckoutToken]);

  const calculateTotal = (services) => {
    if (!services || !Array.isArray(services)) {
      console.error('Invalid services data:', services);
      return;
    }

    const total = services.reduce((sum, service) => {
      const serviceAmount = parseFloat(service.price);
      // console.log(serviceAmount)
      return sum + (isNaN(serviceAmount) ? 0 : serviceAmount);
    }, 0);

    setTotalAmount(total);
  };

  const createPaymentRequest = () => {
    return {
      requestShippingAddress: false,
      requestBillingInfo: true,
      currencyCode: 'USD',
      countryCode: 'US',
      total: {
        label: 'Total',
        amount: dueAmount.toFixed(2),
        pending: false,
      },
      lineItems: bookingData.servicePerformeds.length > 0 && bookingData.servicePerformeds.map(service => ({
        label: service.service.name,
        amount: Number(service.service.price),
        pending: false,
      })),
    };
  };

  const handleCCPayment = async (paymentResult) => {
    const squareButton = document.getElementById('rswp-card-button');
    if (paymentResult.status) {
      try {
        const response = await fetch('/api/processPayment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nonce: paymentResult.token,
            totalAmount: Number(dueAmount), // Convert to cents
            bookingUuid: bookingData.uuid,
            tip: bookingData.tip,
            note: bookingData.servicePerformeds.reduce((total, easp) => {
              return (total + easp.service.name + ' ')
            }, ''),
            bookingData,
          })
        });

        const paymentResponse = await response.json();
        if (paymentResponse.newRecord) {
          setPaymentStatus('Payment successful');
          const updatedServices = [...bookingData.patientPayments, paymentResponse.newRecord];
          squareButton.classList.add('green');
          setTimeout(() => squareButton.classList.remove('green'), 3000);
          handleFieldChange('patientPayments', updatedServices);
          handleFieldChange('patientPaid', true);
          setChangeMade(false)
          fetch('/api/admin/updateBooking', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              uuid: bookingData.uuid,
              discount: bookingData.discount || 0,
              tip: bookingData.tip,
              patientPaid: true,
            })
          })
            .then(response => response.json())
        } else {
          setPaymentStatus('Payment failed');
          // console.log(squareButton)
          squareButton.classList.add('red');
          // console.log(squareButton)
          const style = document.createElement('style');
          style.type = 'text/css';
          style.innerHTML = `
    .c-jWYnUm {
      background-color: red !important;
      color: white !important;
    }
  `;
          document.head.appendChild(style);
          // setTimeout(() => squareButton.classList.remove('red'), 3000);
          // Handle payment failure
        }
      } catch (error) {
        console.error('Error processing payment:', error);
        setPaymentStatus('Error processing payment');
        // console.log(squareButton)
        squareButton.style.backgroundColor = 'red';
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `
  .c-jWYnUm {
    background-color: red !important;
    color: white !important;
  }
`;
        document.head.appendChild(style);
      }
    } else {
      setPaymentStatus('Payment Failed.');
    }
  };

  const handleCashPayment = async () => {
    const button = document.getElementById('cashpaymentbutton');

    try {
      const response = await fetch('/api/admin/processPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nonce: 'cash',
          totalAmount: Number(dueAmount), // Convert to cents
          bookingUuid: bookingData.uuid,
          tip: bookingData.tip,
          bookingData
        })
      });

      // console.log(response.body)
      const paymentResponse = await response.json();
      // console.log(paymentResponse)
      if (paymentResponse.payment) {
        // console.log(paymentResponse)
        setPaymentStatus('Payment successful');
        const updatedServices = [...bookingData.patientPayments, paymentResponse.newRecord];
        fetch('/api/admin/updateBooking', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid: bookingData.uuid,
            discount: bookingData.discount || 0,
            tip: bookingData.tip,
            patientPaid: true,
          })
        })
          .then(response => response.json())
          .then(data => {
            console.log('updated discount and tip', data);
          });
        button.classList.add('green');
        setTimeout(() => button.classList.remove('green'), 3000);
        // console.log(updatedServices)
        // Assuming handleFieldChange updates the parent state and re-renders this component
        handleFieldChange('patientPayments', updatedServices);
        handleFieldChange('patientPaid', true);
        setChangeMade(false)
      } else {
        setPaymentStatus('Payment failed');
        // Handle payment failure
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setPaymentStatus('Error processing payment');
    }
  };

  const addService = (serviceToAdd) => {
    return fetch('/api/admin/addService', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(serviceToAdd)
    })
      .then(response => response.json())
      .then(data => {
        if (data) {
          const addedServiceDetails = { ...data, service: services.concat(addons).filter(ea => ea.uuid === data.serviceUuid)[0] };
          return addedServiceDetails; // Return the new service detail for aggregation
        } else {
          throw new Error('No data returned from fetch');
        }
      })
      .catch(error => {
        console.error('Error adding service:', error);
        throw error; // Propagate the error to be handled in the calling function
      });
  };

  const handleAddServiceClick = () => {
    setShowServicesModal(true); // Show modal to select services
  };

  const handleAddAddonClick = () => {
    setShowAddonModal(true); // Show modal to select services
  };
  const handleSaveDiscount = async () => {
    try {
      const response = await fetch('/api/admin/updateBooking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uuid: bookingData.uuid,
          discount: bookingData.discount || 0,
        }),
      });

      if (response.ok) {
        setUnsavedDiscount(false);
        setPaymentStatus('Discount saved successfully');
      } else {
        setPaymentStatus('Failed to save discount');
      }
    } catch (error) {
      console.error('Error saving discount:', error);
      setPaymentStatus('Error saving discount');
    }
  };

  const handleModalConfirm = async (passedServices) => {
    const newServiceDetails = [];
    for (const service of passedServices) {
      try {
        const addedService = await addService({
          bookingUuid: bookingData.uuid,
          providerId: bookingData.primaryStaffId,
          serviceUuid: service.uuid,
          quantity: 1,
          creatorId: (user && user.id),
        });
        newServiceDetails.push(addedService);
      } catch (error) {
        console.error('Error in adding service:', error);
      }
    }

    // Now update the state once with all new services
    if (newServiceDetails.length > 0) {
      const updatedServices = [...bookingData.servicePerformeds, ...newServiceDetails];
      handleFieldChange('servicePerformeds', updatedServices);
      calculateTotal(updatedServices);
      setChangeMade(false);
    }

    setShowServicesModal(false); // Close modal after adding services
    setShowAddonModal(false); // Close modal after adding services
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // other payment processing
  const handlePaymentConfirmation = async () => {
    setIsModalOpen(false); // Close the modal
    const button = event.target;

    try {
      const response = await fetch('/api/admin/processPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nonce: 'other',
          totalAmount: Number(dueAmount), // Convert to cents
          bookingUuid: bookingData.uuid,
          tip: bookingData.tip
        })
      });

      // console.log(response.body)
      const paymentResponse = await response.json();
      // console.log(paymentResponse)
      if (paymentResponse.payment) {
        // console.log(paymentResponse)
        setPaymentStatus('Payment successful');
        fetch('/api/admin/updateBooking', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid: bookingData.uuid,
            discount: bookingData.discount || 0,
            tip: bookingData.tip,
            patientPaid: true,
          })
        })
          .then(response => response.json())
        const updatedServices = [...bookingData.patientPayments, paymentResponse.newRecord];
        button.classList.add('green');
        setTimeout(() => button.classList.remove('green'), 3000);
        // console.log(updatedServices)
        // Assuming handleFieldChange updates the parent state and re-renders this component
        handleFieldChange('patientPayments', updatedServices);
        handleFieldChange('patientPaid', true);
        setChangeMade(false)
        // Additional logic for successful payment
      } else {
        setPaymentStatus('Payment failed');
        // Handle payment failure
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setPaymentStatus('Error processing payment');
    }
  };
  const PaymentConfirmationModal = () => (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>Please confirm that you are receiving payment as cash:</p>
        <button onClick={handleCashPayment}>Yes, customer paid in cash.</button>{' '}
        <button onClick={closeModal}>Cancel</button>
      </div>
    </div>
  );
  return (
    <div className='payment-container'>
        <button className='service-btn' onClick={handleAddAddonClick}>Add Add On</button> {' '}
        <button className='service-btn' onClick={handleAddServiceClick}>Add Service</button> {' '}
        {unsavedDiscount && (
  <button
    onClick={handleSaveDiscount}
    className="service-btn"
  >
    Save Discount
  </button>
        )}
        {showServicesModal && (
      <ServiceSelectionModal
        services={services}
        // fetchServices={fetchServices}
        // allServices={allServices}
        selectedServices={selectedServices}
        setSelectedServices={setSelectedServices}
        onConfirm={handleModalConfirm}
        onClose={() => setShowServicesModal(false)}
      />
        )}
        {showAddonModal && (
      <ServiceSelectionModal
        services={addons}
        selectedServices={selectedAddons}
        setSelectedServices={setSelectedAddons}
        onConfirm={handleModalConfirm}
        onClose={() => setShowAddonModal(false)}
      />
        )}
    <ServicesTable
      services={bookingData.servicePerformeds}
      tip={bookingData.tip || '0'}
      discount={bookingData.discount || '0'}
      paidAmount={paidAmount}
      dueAmount={dueAmount}
      setTip={(value) => handleFieldChange('tip', value)}
      setDiscount={(value) => {
        handleFieldChange('discount', value);
        setUnsavedDiscount(true);
      }}
      setTotalAmount={setTotalAmount}
      setDueAmount={setDueAmount}
      handleFieldChange={handleFieldChange}
      key={bookingData.servicePerformeds.length}
      setTipWasSelected={setTipWasSelected}
    />

    {paymentStatus && <p className='payment-status'>{paymentStatus}</p>}
    {dueAmount > 0.49 && tipWasSelected === true && <>
      <div className=''>
        {showOthers && <button
  style={{
    backgroundColor: isDisabled ? '#cccccc' : '#0F9E82', // Greyed out when disabled
    color: isDisabled ? '#666666' : 'white', // Darker text when disabled
    padding: '10px 20px', // Padding: 10px top and bottom, 20px left and right
    fontSize: '16px', // Font size
    border: 'none', // No border
    borderRadius: '5px', // Rounded corners
    cursor: 'pointer', // Cursor changes to a pointer on hover
    outline: 'none', // Remove outline on focus (for accessibility, consider keeping it or styling it appropriately)
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' // Subtle shadow for depth
  }}
  className="tip-button"
  disabled={isDisabled} // Button is disabled based on isDisabled state
  onClick={handlePaymentClick}
>
  Pay With Card
</button>}
<br/>
<div onClick={() => hideOtherPayments()}><BraintreeDropIn show={true} onPaymentCompleted={processBraintreeTransaction} setIsDisabled={setIsDisabled} dueAmount={dueAmount} /></div>
{paymentStatus && paymentStatus}
{!showOthers && <><br/><button onClick={() => showOtherPayments()} style={{
  backgroundColor: '#0F9E82', // Greyed out when disabled
  color: 'white', // Darker text when disabled
  padding: '10px 20px', // Padding: 10px top and bottom, 20px left and right
  fontSize: '16px', // Font size
  border: 'none', // No border
  borderRadius: '5px', // Rounded corners
  cursor: 'pointer', // Cursor changes to a pointer on hover
  outline: 'none', // Remove outline on focus (for accessibility, consider keeping it or styling it appropriately)
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
  width: '100%'
}}>Cancel</button></>}
      {/* <PaymentForm
        applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
        locationId="LB0XPMNHK4RTY"
        createPaymentRequest={createPaymentRequest}
        cardTokenizeResponseReceived={handleCCPayment}
      >
        <fieldset className="sq-fieldset">
          <CreditCard />
          <ApplePay />
        </fieldset>
      </PaymentForm> */}
    </div>
      {showOthers && <button id="cashpaymentbutton" onClick={openModal} className='service-btn' style={{ width: '100%' }}>Paid By Cash</button>}
      {isModalOpen && <PaymentConfirmationModal />}
      {/* <button onClick={openModal} className='service-btn' style={{ width: '100%' }}>
        Paid Luxe By Other Method
      </button> */}
</>}
      <p className='todo'>
        {/* * Needs to check to make sure waiver is complete. If not, do not allow to press payment <br/> */}
      </p>
  </div>
  )
}

export default PaymentTabContent;
