import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import './adminStyles.css';

const ViewSchedule = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [workSchedules, setWorkSchedules] = useState({});
  const [user, setUser] = useState(null);
  const { cityId } = useParams();
  const [loadingMessage, setLoadingMessage] = useState('Loading...');

  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          console.log(data);
          setIsAuthenticated(true);
          setUser(data.user);
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  useEffect(() => {
    if (user) {
      fetchWorkSchedule(currentMonth);
    }
  }, [user, currentMonth]);

  const fetchWorkSchedule = async (month) => {
    setLoadingMessage('Loading...');
    const startDate = month.clone().startOf('month');
    const endDate = month.clone().endOf('month');
    const schedules = {};

    for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
      const response = await fetch('/api/admin/getWorkScheduleOverview', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          date: date.format('YYYY-MM-DD'),
          city: cityId,
        }),
      });
      const data = await response.json();
      // console.log(data);
      
      if (user.acl < 4) {
        schedules[date.format('YYYY-MM-DD')] = data.filter(schedule => schedule.staff.id === user.id);
      } else {
        schedules[date.format('YYYY-MM-DD')] = data;
      }
    }
    setWorkSchedules(schedules);
    setLoadingMessage(null);
  };

  const increaseMonth = () => {
    setCurrentMonth(prev => prev.clone().add(1, 'month'));
  };

  const decreaseMonth = () => {
    setCurrentMonth(prev => prev.clone().subtract(1, 'month'));
  };

  if (!isAuthenticated || !user) {
    return <div>Loading...</div>;
  }

  const renderCalendar = () => {
    const startDate = currentMonth.clone().startOf('month');
    const endDate = currentMonth.clone().endOf('month');
    const calendar = [];

    // Add weekday headers
    const weekdays = moment.weekdaysShort();
    weekdays.forEach(day => {
      calendar.push(
        <div key={`header-${day}`} className="calendar-header">
          {day}
        </div>
      );
    });

    // Fill in empty cells for days before the start of the month
    const firstDayOfMonth = startDate.day();
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendar.push(<div key={`empty-start-${i}`} className="calendar-day empty"></div>);
    }

    for (let day = startDate.clone(); day.isSameOrBefore(endDate); day.add(1, 'day')) {
      const formattedDate = day.format('YYYY-MM-DD');
      const daySchedules = workSchedules[formattedDate] || [];

      calendar.push(
        <div key={formattedDate} className="calendar-day">
          <div className="date">
            <span className="day-number">{day.format('D')} - </span>
            <span className="day-name">{day.format('ddd')}</span>
          </div>
          <div className="schedules">
            {daySchedules.map((schedule, index) => (
              <div key={index} className="schedule-item" style={{backgroundColor: schedule.staff.extraStuff?.color}}>
                {schedule.staff.firstName} {schedule.staff.lastName}: {moment(schedule.startTime).format('h:mma')} - {moment(schedule.endTime).format('h:mma')}
                <hr/>
              </div>
            ))}
          </div>
        </div>
      );
    }

    // Fill in empty cells for days after the end of the month
    const lastDayOfMonth = endDate.day();
    for (let i = lastDayOfMonth; i < 6; i++) {
      calendar.push(<div key={`empty-end-${i}`} className="calendar-day empty"></div>);
    }

    return calendar;
  };

  return (
    <div className="adminParent monthlySchedule">
      <h1 className='todo' style={{ textAlign: 'center' }}>{loadingMessage}</h1>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
        <button onClick={decreaseMonth} style={{ marginRight: '10px' }}>-</button>
        <h2 style={{ margin: '0', fontSize: '24px', fontWeight: 'bold' }}>{currentMonth.format('MMMM YYYY')}</h2>
        <button onClick={increaseMonth} style={{ marginLeft: '10px' }}>+</button>
      </div>
      <h3 style={{ textAlign: 'center', fontSize: '20px', marginBottom: '10px' }}>
        {user.acl < 4 ? 'Your Monthly Schedule' : 'Staff Monthly Schedule'}
      </h3>
      <div className="calendar">
        {renderCalendar()}
      </div>
      <div className="calendar-bottom-space">
        <button onClick={() => navigate('/admin/byCity/' + cityId)} className="navigate-button">
          Back
        </button>
      </div>
    </div>
  );
};

export default ViewSchedule;