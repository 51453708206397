// Function to format date
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

// Function to format medical notes
const formatMedicalNotes = (medicalNotes) => {
  if (!medicalNotes) return 'No info';
  console.log('medicalNotes', medicalNotes)

  // If it's a string, return it directly
  if (typeof medicalNotes === 'string') {
    return medicalNotes;
  }

  // If it's an object, format it as a list of key-value pairs
  return (
        <ul>
          {Object.entries(medicalNotes).map(([key, value]) => {
            if (key == 'NurseNotes') {
              console.log(value)
              return (value.map((eachNote, index) => {
                return (<li key={index}>
              <strong>Nurse Note - {eachNote.date}:</strong> {eachNote.note.toString()}
            </li>)
              }))
            } else {
              return (<li key={key}>
              <strong>{key}:</strong> {value.toString()}
            </li>)
            }
          })}
        </ul>
  );
};

// Function to format price in checkout displays
const formatPrice = (priceInDollars) => {
  const numericPrice = parseFloat(priceInDollars);
  return isNaN(numericPrice) ? '0.00' : numericPrice.toFixed(2);
};

// Export the function for use in other files
export { formatDate, formatMedicalNotes, formatPrice };
