// BookingDetailsContainer.jsx
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import TabContainer from './generalComponents/TabContainer'
import TabContent from './generalComponents/TabContent'
import GeneralTabContent from './adminBooking/GeneralTabContent.js'
import MedicalTabContent from './adminBooking/MedicalTabContent'
import PaymentTabContent from './adminBooking/PaymentTabContent'
import PatientHistoryTabContent from './adminBooking/PatientHistoryTabContent.js'
import CustomerServiceContent from './adminBooking/CustomerServiceContent'
import WeightLossContent from './adminBooking/WeightLoss.js'
import './adminStyles.css'
import { formatDate } from './utility/module.js';

const BookingDetailsContainer = () => {
  const { bookingUUID } = useParams()
  const [bookingData, setBookingData] = useState(null)
  const [changeMade, setChangeMade] = useState(false)
  const navigate = useNavigate()
  const [user, setUser] = useState('');

  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setUser(data.user)
          // fetchWorkSchedule(currentDate); // Fetch work schedules for the initial date on page load
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  useEffect(() => {
    // Fetch data from the backend when the component mounts OR CHANGES THE BOOKINGDATA
    fetch('/api/admin/booking/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: bookingUUID,
      })
    })
      .then(response => response.json())
      .then(data => {
        setBookingData(data)
        // console.log(data)
      })
      .catch(error => {
        console.error('Error fetching booking details:', error)
      })
  }, [bookingUUID])

  if (!bookingData) {
    return <div>Loading booking details...</div>
  }

  const handleFieldChange = (field, value) => {
    setChangeMade(true)
    const keys = field.split('.')
    // console.log(bookingData)
    // console.log(keys)
    setBookingData(prevData => {
      const data = { ...prevData }
      keys.reduce((o, k, i) => {
        if (i === keys.length - 1) {
          o[k] = value
        } else {
          o[k] = o[k] || {}
        }
        return o[k]
      }, data)
      return data
    })
  }

  const submitChanges = () => {
    const keys = field.split('.')
    // console.log(bookingData)
    // console.log(keys)
    setBookingData(prevData => {
      const data = { ...prevData }
      keys.reduce((o, k, i) => {
        if (i === keys.length - 1) {
          o[k] = value
        } else {
          o[k] = o[k] || {}
        }
        return o[k]
      }, data)
      return data
    })
  }

  return (
  <div>
<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
      <p>
        <button className="navigate-button" onClick={() => navigate('/admin/byCity/' + bookingData.cityCalendarId)}>
          {'<'} Admin home
        </button>
      </p>
      <div className='bookingDetailTopRight'>
        <b>Name:</b> {bookingData.patient.firstName + ' ' + bookingData.patient.lastName} &nbsp;
        <b>Nurse:</b> {bookingData.staff.firstName + ' ' + bookingData.staff.lastName} <br/>
        <b>Booking Start Time:</b> {formatDate(bookingData.startTime)} <br/>
        <b>Booking End Time:</b> {formatDate(bookingData.endTime)}<br/>
        <b>Calendar Block Start Time:</b> {formatDate(bookingData.calendarBlock.startTime).split(' ')[1] + formatDate(bookingData.calendarBlock.startTime).split(' ')[2]}<br/>
        <b>Calendar Block End Time:</b> {formatDate(bookingData.calendarBlock.endTime).split(' ')[1] + formatDate(bookingData.calendarBlock.endTime).split(' ')[2]}<br/>
        <b>Status:</b> {bookingData.status}
      </div>
    </div>
    <TabContainer changeMade={changeMade} setChangeMade={setChangeMade}>
      <div label="General Info">
        <GeneralTabContent bookingData={bookingData} handleFieldChange={handleFieldChange} setChangeMade={setChangeMade}/>
      </div>
      {
        (user.canSeeMedicalInfo === true)
          ? <div label="Medical Info">
              <MedicalTabContent bookingData={bookingData} handleFieldChange={handleFieldChange} setChangeMade={setChangeMade}/>
            </div>
          : <div style={{ display: 'none' }}></div>
      }
      <div label="Payment">
        <PaymentTabContent bookingData={bookingData} handleFieldChange={handleFieldChange} setChangeMade={setChangeMade}/>
      </div>
      <div label="Patient History">
        <PatientHistoryTabContent bookingData={bookingData} handleFieldChange={handleFieldChange} setChangeMade={setChangeMade}/>
      </div>
      {
        (user.acl > 2)
          ? <div label="Customer Service">
              <CustomerServiceContent bookingData={bookingData} handleFieldChange={handleFieldChange} setChangeMade={setChangeMade}/>
            </div>
          : <div style={{ display: 'none' }}></div> // Render an empty div when the condition is false
      }
      {
        (user.acl > 2 && bookingData.extraStuff && bookingData.extraStuff.serviceType === 'weight loss')
          ? <div label="Weight Loss">
              <WeightLossContent bookingData={bookingData} handleFieldChange={handleFieldChange} setChangeMade={setChangeMade}/>
            </div>
          : <div style={{ display: 'none' }}></div> // Render an empty div when the condition is false
      }

    </TabContainer>
  </div>
  )
}

export default BookingDetailsContainer
