const state = {
  node: {
    type: 'Content',
    treeId: 6,
    node: 1,
    is_root: '0',
    node_name: '',
    page_title: 'Exit Survey',
    content:
      `<div>
      <h2 style="text-align: left;">Thank you for using Luxe Mobile IV.</h2>
    </div>`,
    question: '',
    x: '510',
    y: '259',
    jsmessage: '',
    messages: {},
    buttons: {
      0: {
        op: '',
        rank: '1',
        value: '0',
        updated: '0',
        hover_text: '',
        button_data: '0',
        button_link: '2',
        button_text: 'Start Survey',
        wizard_skip: '0',
        project_node_id: '6',
        logic_expression: ''
      },
    },
  },
  formData: {},
  formFields: {
    0: {
      name: 'overallExperience',
      label: 'How would you rate your overall experience with Luxe Mobile IV?',
      type: 'radio',
      options: 'Excellent\nGood\nAverage\nPoor\nVery Poor',
      required: '1',
    },
    1: {
      name: 'nurseRating',
      label: 'How would you rate the professionalism and care provided by our nurses?',
      type: 'radio',
      options: 'Excellent\nGood\nAverage\nPoor\nVery Poor',
      required: '1',
    },
    2: {
      name: 'bookingProcess',
      label: 'How easy was the booking process?',
      type: 'radio',
      options: 'Very Easy\nEasy\nNeutral\nDifficult\nVery Difficult',
      required: '1',
    },
    3: {
      name: 'recommendService',
      label: 'How likely are you to recommend Luxe Mobile IV to others?',
      type: 'radio',
      options: 'Very Likely\nLikely\nNeutral\nUnlikely\nVery Unlikely',
      required: '1',
    },
    4: {
      name: 'comments',
      label: 'Please provide any additional comments or feedback:',
      type: 'multiline',
      options: '',
      required: '0',
    },
  },
  nodesToGoBack: 1,
  renderedContent: '',
  renderedButtons: '',
  renderedForm: '',
  sessionId: '',
};

module.exports = state;
