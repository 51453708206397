import React, { useState, useEffect } from 'react';
import LogoutButton from './LogoutButton';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

const AdminHomeByCity = () => {
  const navigate = useNavigate();
  const { cityId } = useParams();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [user, setUser] = useState('');
  const [cityCalendars, setCityCalendars] = useState([]);
  const [workSchedules, setWorkSchedules] = useState([]);

  useEffect(() => {
    // Check user's authentication status when component mounts
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
          setUser(data.user);
          setSelectedCity(data.user.cityId);
          return data.user;
        } else {
          // If not authenticated, redirect to login
          window.location.href = '/login';
        }
      }).then((rsp) => {
        console.log('user', rsp);
        fetch('/api/admin/getWorkScheduleByNurse', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nurseId: rsp.id,
            startTime: moment().toISOString(),
            endTime: moment().add(7, 'days').toISOString()
          }),
        })
          .then(response => response.json())
          .then(data => {
            console.log('workSchedules response', data);
            setWorkSchedules(data);
          });
      });

    // Fetch city calendars from the API endpoint
    fetch('/api/getCityCalendars', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log('cities', data);
        // all cities to data
        data.push({ id: 0, name: 'All Cities' })
        if (Array.isArray(data)) {
          setCityCalendars(data);
        }
      });
  }, []);

  useEffect(() => {
    // Update the URL parameter when the selected city changes
    if (selectedCity) {
      navigate(`/admin/byCity/${selectedCity}`);
    }
  }, [selectedCity, navigate]);

  useEffect(() => {
    // Set selectedCity when cityId is available
    if (cityId) {
      setSelectedCity(cityId);
    }
  }, [cityId]);

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  const renderWorkSchedule = () => {
    // console.log('workSchedules', workSchedules);
    // map over workSchedules and render start and end time
    return workSchedules.map(ws => (
      <div key={ws.id}>
        <span>
          {moment(ws.startTime).format('MM/DD/YYYY ddd hh:mm a')} -{' '}
          {moment(ws.endTime).format('hh:mm a')}
        </span>
      </div>
    ));
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setSelectedCity(selectedCity);
  };

  return (
    <div className='adminParent' style={{ textAlign: 'left' }}>
      <h1>Luxe City Dashboard</h1>
      <label htmlFor="citySelect">Select a city: </label>
      <select
        id="citySelect"
        value={selectedCity}
        onChange={handleCityChange}
        disabled={user.acl < 5}
      >
        {cityCalendars.map(city => (
          <option key={city.id} value={city.id}>
            {city.name}
          </option>
        ))}
      </select><br />
      {workSchedules.length > 0 ? <> <h3>Your Upcoming Shifts</h3> {renderWorkSchedule()} </> : null}
      {user.acl > 2 && (
        <div>
          <h3>Customer Service Views</h3>
          <button onClick={() => navigate(`/admin/MonochromeStaffAvailabilityCalendar/${selectedCity}`)}>
            Customer Service Schedule Availability View
          </button>
          <br />
          <button onClick={() => navigate(`/admin/scheduleAvailability/${selectedCity}`)}>
            Aisha Schedule Availability View
          </button>
          <br />
          <button onClick={() => navigate(`/admin/schedulemap/${selectedCity}`)}>
            Schedule Map View
          </button>
          <br />
        </div>
      )}
      <h3>View Bookings By Type</h3>
      <button onClick={() => navigate(`/admin/bookingTableView/booked/${selectedCity}`)}>
        New Bookings
      </button>
      <i>Look here for new bookings</i> <br />
      <button onClick={() => navigate(`/admin/bookingTableView/canceled/${selectedCity}`)}>
        Canceled Bookings
      </button>
      <br />
      <button onClick={() => navigate(`/admin/bookingTableView/patientPaid/${selectedCity}`)}>
        Paid Bookings
      </button>
      <br />
      <button onClick={() => navigate('/admin/paidBookingsNurseView')}>
        Historical Paid Bookings
      </button>
      {user.acl > 2 && (
        <div>
          <button onClick={() => navigate(`/admin/bookingTableView/customer-serviceIssue-pending/${selectedCity}`)}>
            Bookings With Customer Service Issues
          </button>
          <br />
          <button onClick={() => navigate(`/admin/bookingTableView/all/${selectedCity}`)}>
            All Bookings
          </button>
          <br />
          <button onClick={() => navigate(`/admin/searchBookings/${selectedCity}`)}>
            Search Bookings
          </button>
          <br />
          {user.acl > 4 && (<span className='todo'> Can't find a booking? Select "all cities", then look at "all bookings"</span>)}
          <h3>Create Bookings </h3>
            <button onClick={() => navigate(`/admin/createBooking/${selectedCity}`, { city: selectedCity })}>
              Create a new booking
            </button>
        </div>
      )}
      {user.acl > 4 && (
        <>
      <h3>Patient Search</h3>

        <div>
          <button onClick={() => navigate(`/admin/lookupPatient`)}>
            Lookup Patient
          </button>
          <br />
        </div></>
      )}
      <h3>Checkout / Collect Payment </h3>
        <button onClick={() => navigate(`/admin/SelectBookingsForCheckout/booked/${selectedCity}`)}>
          Multi-Person Checkout
        </button>
      <h3>Work Schedules</h3>
      <button onClick={() => navigate(`/admin/workSchedule/${selectedCity}`)}>
        Work Schedule View
      </button>  <i> See when you're on the schedule by day</i> <br />
      <button onClick={() => navigate(`/admin/dynamicScheduleView/${selectedCity}`)}>
        Dynamic Work Schedule Overview
      </button>
      <br/>
    
      <button onClick={() => navigate(`/admin/MonthlySchedule/${selectedCity}`)}>
            Monthly Schedule Overview
      </button> <i> See when we have shifts available</i>
      <br />
      <button onClick={() => navigate(`/admin/ViewSchedule/${selectedCity}`)}>
            View Scheduled Shifts
      </button> <i> See when you are currently scheduled over the next month</i>
      <br />
      <br />
      <button onClick={() => navigate('/admin')}>Admin Home</button> <br />
      <LogoutButton />
    </div>
  );
};

export default AdminHomeByCity;
