import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Footer from '../generalComponents/footer';
import Navbar from '../generalComponents/Navbar';
import './master.css';

const ThankYou = () => {
  const { cityId: urlCityId } = useParams();
  const [reviewLinks, setReviewLinks] = useState({
    google: '',
    yelp: '',
    facebook: ''
  });

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryCityId = params.get('cityId');
    
    // Use the cityId from URL params if available, otherwise use from query params
    const cityId = urlCityId || queryCityId;

    // Define review links for each city
    const cityReviewLinks = {
      '1': {
        google: 'https://g.page/r/CW6-TLtM7hhBEB0/review',
        yelp: 'https://www.yelp.com/writeareview/biz/2IGhxlzJYNK6Z0xHWlIt-A?return_url=%2Fbiz%2F2IGhxlzJYNK6Z0xHWlIt-A&review_origin=biz-details-war-button',
        facebook: 'https://www.facebook.com/luxemobileiiv/reviews'
      },
      '2': {
        google: 'https://g.page/r/CcQj6kC4HfCKEBM/review',
        yelp: 'https://www.yelp.com/writeareview/biz/2IGhxlzJYNK6Z0xHWlIt-A?return_url=%2Fbiz%2F2IGhxlzJYNK6Z0xHWlIt-A&review_origin=biz-details-war-button',
        facebook: 'https://www.facebook.com/luxemobileiiv/reviews'
      },
      '4': {
        google: 'https://g.page/r/CWi3ACpGHlG0EBM/review',
        yelp: 'https://www.yelp.com/writeareview/biz/7Jv6lf5ea0qmgaCKOOThjA?return_url=%2Fbiz%2F7Jv6lf5ea0qmgaCKOOThjA&review_origin=biz-details-war-button',
        facebook: 'https://www.facebook.com/profile.php?id=100069697836722&sk=reviews'
      },
    };

    // Set the review links based on the cityId
    console.log(cityReviewLinks)
    console.log(cityId)
    console.log(cityReviewLinks[cityId])
    setReviewLinks(cityReviewLinks[cityId] || cityReviewLinks['1']); // Default to city 1 if cityId is not found
  }, [location, urlCityId]);

  const handleReviewClick = (platform) => {
    window.dataLayer.push({ event: `${platform}_REVIEW` });
  };

  return (
    <>
      <Navbar />
      <div style={{ height: '100vh', textAlign: 'center', marginTop: "100px" }} id="exitSurvey">
        <br />
        <div className="mt-5 mx-4 text-center" style={{ fontSize: '30px' }}>
          <b style={{ fontWeight: '500' }}>Thank you for using Luxe Mobile IV!</b><br />
          <div style={{ fontSize: '18px', lineHeight: '1.4', fontWeight: '300' }}>
            Would you take a moment to share your thoughts by leaving a review?
          </div>
          <a href={reviewLinks.google} onClick={() => handleReviewClick('G')}>
            <button className="bookOnlineButton">Leave a Google Review!</button>
          </a><br />
          <a href={reviewLinks.yelp} onClick={() => handleReviewClick('Y')}>
            <button className="bookOnlineButton">Leave a Yelp Review!</button>
          </a><br />
          <a href={reviewLinks.facebook} onClick={() => handleReviewClick('F')}>
            <button className="bookOnlineButton">Leave a Facebook Review!</button>
          </a>
          <br /><br />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ThankYou;